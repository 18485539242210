import React, { useState } from "react";
import LeadList from "./LeadList";
import Graph from "./Graph";
import Filter from "./Filter";
import LeftSlider from "../../Inc/LeftSlider";
import EditPatient from "./EditPatient";
import LeadSearch from "../../Common/LeadSearch";
import { useGetLeadApi } from "../../context/getAllLeadContext";

const Home = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const { displayData } = useGetLeadApi();

  const toggleEdit = (id) => {
    setShowEdit(!showEdit);
    setSelectedPatientId(id);
  };
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleFilter = () => {
    setStartDate("");
    setEndDate("");
  };

  return (
    <>
      <div className="medflick-mod">
        <LeftSlider path="home" />
        <div className="medflick-mod-right">
          <div className="scrollbar-1">
            <Graph />

            <div className="leads-search-doc">
              <h2>Leads</h2>

              <LeadSearch />
            </div>
            <div className="lead-search">
              <input
                type="date"
                className="lead-search-input"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
              />
              <input
                type="date"
                className="lead-search-input"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
              />
              <button className="lead-search-button" onClick={handleFilter}>
                Clear
              </button>
            </div>
            {startDate && endDate && (
              <div className="leads-search-doc" style={{ marginTop: "10px" }}>
                <h2>
                  Total Number of Leads{" "}
                  <span style={{ fontWeight: "bold" }}>{startDate}</span> to{" "}
                  <span style={{ fontWeight: "bold" }}>{endDate}</span> ={" "}
                  <span style={{ color: "#ff6800", fontWeight: "bold" }}>
                    {displayData?.length} Leads
                  </span>
                </h2>
              </div>
            )}

            <div className="leads-patient-box">
              <div className="leads-patient-head">
                <div className="leads-patient-head-box1">Patient Name</div>
                <div className="leads-patient-head-box2">Country</div>
                <div className="leads-patient-head-box3">Source</div>
                <div className="leads-patient-head-box4">Lead Type</div>
                <div className="leads-patient-head-box5">Report Status</div>
                <div className="leads-patient-head-box6">Assign To</div>
                <div className="leads-patient-head-box7">Status</div>
                <div className="leads-patient-head-box8">Action</div>
              </div>

              <LeadList
                toggleEdit={toggleEdit}
                showEdit={showEdit}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
      </div>
      <EditPatient
        toggleMessage={toggleEdit}
        showMessage={showEdit}
        selectedPatientId={selectedPatientId}
      />
    </>
  );
};

export default Home;
