import { axiosInstance } from "../axiosInstance/axiosInstance";
import React, { createContext, useState, useContext, useEffect } from "react";

const GetLeadContext = createContext();

export const GetLeadProvider = ({ children }) => {
  const [allLead, setAllLead] = useState([]);
  const [websiteLead, setWebsiteLead] = useState([]);
  const [totalLead, setTotalLead] = useState("");
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState(null);
  const [leadDetail, setLeadDetail] = useState("");
  const [patienLeadDetail, setPatientLeadDetail] = useState("");
  const [patinetReport, setPatientReport] = useState([]);
  const [patientSpeciality, setPatientSpeciality] = useState("");
  const [patientJourney, setPatientJourney] = useState("");
  const [userLeadDetail, setUserLeadDetail] = useState("");
  const [patientConversation, setPatientConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [platformSelect, setPlatformSelect] = useState("facebook");
  const [patientDetail, setPatientDetail] = useState("");
  const [totalpage, setTotalPage] = useState("");
  const [page, setPage] = useState(1);

  // Search Lead

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  // const getAllLead = async (page) => {
  //   setLoading(true);
  //   try {
  //     const response = await axiosInstance.get(`/leads?page=${page}`);
  //     setAllLead(response.data.leads);
  //     setTotalLead(response.data.totalLeads);
  //     setTotalPage(response.data?.totalPages);

  //     return response.data;
  //   } catch (err) {
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getAllLead = async (page, startDate = "", endDate = "") => {
    setLoading(true);
    try {
      // Build the API URL with start and end dates
      let url = `/leads?page=${page}`;
      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`;
      }
      // if (endDate) {
      //   url += `&end_date=${endDate}`;
      // }

      const response = await axiosInstance.get(url);
      setAllLead(response.data.leads);
      setTotalLead(response.data.totalLeads);
      setTotalPage(response.data?.totalPages);

      return response.data;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (query) {
        setSearchLoading(true);
        try {
          const response = await axiosInstance.get(
            `/SearchleadByName?q=${query}`
          );
          setResults(response.data?.data || []);
        } catch (err) {
          console.log("error", err);
        } finally {
          setSearchLoading(false);
        }
      } else {
        getAllLead(page);
      }
    };

    const debounceFetch = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(debounceFetch);
  }, [query]);

  const getLeadbyId = async (id) => {
    if (id) {
      try {
        const response = await axiosInstance.get(`/getLeaddetails/${id}`);
        setLeadDetail(response.data.lead_data);
        setPatientLeadDetail(response.data.patient);
        setUserLeadDetail(response.data.user);
        setPatientReport(response.data.patientReport);
        setPatientSpeciality(response.data.patient_speciality);
        setPatientJourney(response.data.patientJourneydata);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const getConversion = async (patientId, userId, patientJourneyId) => {
    if (patientId && userId && patientJourneyId) {
      try {
        const response = await axiosInstance.get(
          `/getConversationList/${patientId}/${userId}/${patientJourneyId}`
        );
        setPatientConversation(response.data.data);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const getPatientDetail = async (id) => {
    if (id) {
      try {
        const response = await axiosInstance.get(`/patientedit/${id}`);
        setPatientDetail(response.data.patient_data);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const displayData = query ? results : allLead;

  return (
    <GetLeadContext.Provider
      value={{
        allLead,
        status,
        error,
        getAllLead,
        getLeadbyId,
        leadDetail,
        totalLead,
        websiteLead,
        loading,
        setPlatformSelect,
        platformSelect,
        patienLeadDetail,
        userLeadDetail,
        patinetReport,
        patientSpeciality,
        patientJourney,
        getConversion,
        patientConversation,
        getPatientDetail,
        patientDetail,
        totalpage,
        page,
        setPage,
        page,
        query,
        setQuery,
        results,
        searchLoading,
        displayData,
      }}
    >
      {children}
    </GetLeadContext.Provider>
  );
};

export const useGetLeadApi = () => {
  return useContext(GetLeadContext);
};
